import { Link } from "gatsby"
import React from "react"

const AboutSection = () => {
  return (
    <>
      <section className="no-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-us">
                <h4 className="width-50 md-width-60 font-size38 sm-font-size34 xs-font-size30 line-height-45 font-weight-500 margin-30px-bottom sm-margin-20px-bottom xs-margin-15px-bottom sm-width-95 xs-width-100">
                  Stonebridge Global Partners
                </h4>
                <p className="margin-40px-bottom sm-margin-30px-bottom xs-margin-20px-bottom width-70 md-width-80 sm-width-95 xs-width-100">
                  Stonebridge Global Partners was founded in 2013 to buy and
                  preserve affordable housing.
                </p>
                <p className="margin-40px-bottom sm-margin-30px-bottom xs-margin-20px-bottom width-70 md-width-80 sm-width-95 xs-width-100">
                  Bridging the gap and fighting generational cycles of poverty,
                  while preserving and enhancing the quality of life of
                  low-income families. Our mission is to inspire ambition and
                  create opportunities in our communities.
                </p>
                <Link to="/aboutus" className="butn">
                  more
                </Link>
              </div>
            </div>
            <div className="col-md-6 hidden sm:flex align-middle">
              <div className="m-auto">
                <img src="img/about3.jpg" className="rounded-2xl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 sm-margin-30px-bottom">
              <div className="theme-shadow rounded-2xl padding-30px-lr padding-40px-tb">
                <i className="ti-star font-size44 xs-font-size40 text-theme-color"></i>
                <h5 className="font-size20 margin-20px-bottom margin-30px-top xs-margin-20px-top">
                  34
                </h5>
                <p className="no-margin-bottom font-semibold">Communities</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 sm-margin-30px-bottom">
              <div className="theme-shadow rounded-2xl padding-30px-lr padding-40px-tb">
                <i className="ti-home font-size44 xs-font-size40 text-theme-color"></i>
                <h5 className="font-size20 margin-20px-bottom margin-30px-top xs-margin-20px-top">
                  4,721
                </h5>
                <p className="no-margin-bottom font-semibold">Homes</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 xs-margin-30px-bottom">
              <div className="theme-shadow rounded-2xl padding-30px-lr padding-40px-tb">
                <i className="ti-user font-size44 xs-font-size40 text-theme-color"></i>
                <h5 className="font-size20 margin-20px-bottom margin-30px-top xs-margin-20px-top">
                  10,000+
                </h5>
                <p className="no-margin-bottom font-semibold">Families</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="theme-shadow rounded-2xl padding-30px-lr padding-40px-tb">
                <i className="ti-heart font-size44 xs-font-size40 text-theme-color"></i>
                <h5 className="font-size20 margin-20px-bottom margin-30px-top xs-margin-20px-top">
                  40,000+
                </h5>
                <p className="no-margin-bottom font-semibold">Lives</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutSection
