import React from "react"
import Partners from "../components/common/Partners"
import AboutSection from "../components/home/AboutSection"
import LatestProperties from "../components/home/LatestProperties"
import MainBanner from "../components/home/MainBanner"
import Layout from "../components/navigation/Layout"
import AllPropertiesMap from "../components/properties/AllPropertiesMap"

const Home = () => {
  return (
    <Layout>
      <MainBanner />
      <AboutSection />
      <LatestProperties />
      <AllPropertiesMap />
      <Partners />
    </Layout>
  )
}

export default Home
