import { graphql, navigate, useStaticQuery } from "gatsby"
import React from "react"
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"
import { MapTheme } from "../../utils/MapTheme"

const PropertiesMap = withGoogleMap(props => (
  <GoogleMap
    options={{ styles: MapTheme }}
    defaultZoom={5}
    defaultCenter={{
      lat: 37.8393,
      lng: -84.27,
    }}
  >
    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
      {props.properties &&
        props.properties.map(node => {
          const property = node.node.frontmatter
          return (
            <Marker
              label={{
                color: "black",
                fontWeight: "bold",
                text: property.title,
              }}
              position={{
                lat: parseFloat(property.lat),
                lng: parseFloat(property.long),
              }}
              onClick={e => {
                navigate(`/properties/${property.slug}`)
              }}
            />
          )
        })}
    </MarkerClusterer>
  </GoogleMap>
))

const AllPropertiesMap = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              slug
              title
              lat
              long
            }
          }
        }
      }
    }
  `)

  return (
    <section>
      <div className="container">
        <div className="margin-50px-bottom xs-margin-40px-bottom text-center">
          <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            Our communities
          </h4>
        </div>
        <div>
          <PropertiesMap
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            properties={data.allMarkdownRemark.edges}
          />
        </div>
      </div>
    </section>
  )
}

export default AllPropertiesMap
