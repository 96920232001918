import { Link } from "gatsby"
import React from "react"
import BackgroundSlider from "react-background-slider"

const MainBanner = () => {
  return (
    <section
      className="clear full-screen cover-background line-banner"
      data-overlay-dark="6"
      style={{ backgroundColor: "clear" }}
    >
      <BackgroundSlider
        images={[
          "/img/backgrounds/slider/1.jpg",
          "/img/backgrounds/slider/2.jpg",
          "/img/backgrounds/slider/3.jpg",
          "/img/backgrounds/slider/4.jpg",
          "/img/backgrounds/slider/5.jpg",
          "/img/backgrounds/slider/6.jpg",
          "/img/backgrounds/slider/7.jpg",
          "/img/backgrounds/slider/8.jpg",
          "/img/backgrounds/slider/9.jpg",
          "/img/backgrounds/slider/10.jpg",
        ]}
        duration={4}
        transition={2}
      />
      <div className="container h-100">
        <div className="display-table h-100">
          <div className="display-table-cell vertical-align-middle caption">
            <div className="overflow-hidden width-75 sm-width-80 xs-width-95 mobile-width-auto">
              <p className=" text-4xl md:text-6xl text-theme-color margin-40px-bottom sm-margin-20px-bottom font-weight-600 italic">
                Building communities & serving residents
              </p>
              <h1 className="margin-60px-bottom text-white">
                Stonebridge Global Partners
              </h1>
              <Link to="/communities" className="butn theme">
                <span className="alt-font">our communities</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainBanner
