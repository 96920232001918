import { Link } from "gatsby"
import React from "react"

type Props = {
  property: any
}

const GridViewProperty = ({ property }: Props) => {
  const { slug, title, city, state, type, units } = property
  return (
    <div className="col-md-4 margin-30px-bottom">
      <div className="properties-1 rounded-2xl shadow-md">
        <div className="properties-img overflow-hidden">
          <Link to={`/properties/${slug}`}>
            <img
              src={`/img/properties/${slug}/0.jpg`}
              alt={title}
              style={{ height: "273px", objectFit: "cover", width: "100%" }}
            />
          </Link>
        </div>
        <div className="padding-40px-all">
          <div className="d-flex align-items-center justify-content-between margin-20px-bottom">
            <div>
              <h5 className="font-weight-400 font-size20 margin-5px-bottom">
                <Link
                  to={`/properties/${slug}`}
                  className="text-extra-dark-gray"
                >
                  {title}
                </Link>
              </h5>
              <p className="no-margin-bottom">
                {city}, {state}
              </p>
            </div>
            {/* <div>
              <span className="font-size28 text-theme-color">$56</span>
            </div> */}
          </div>
          <ul className="text-extra-light-gray list-style4">
            <li className="text-extra-dark-gray">
              Units<span className="text-right">{units}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GridViewProperty
