import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import GridViewProperty from "../properties/GridViewProperty"

const LatestProperties = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 3
        filter: { frontmatter: { isLatest: { eq: true } } }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              city
              units
              state
            }
          }
        }
      }
    }
  `)

  return (
    <section>
      <div className="container">
        <div className="margin-50px-bottom xs-margin-40px-bottom text-center">
          <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            Featured Communities
          </h4>
        </div>

        <div className="row">
          {data.allMarkdownRemark.edges.map(edge => {
            const property = edge.node.frontmatter
            return <GridViewProperty key={property.slug} property={property} />
          })}
        </div>
      </div>
    </section>
  )
}

export default LatestProperties
